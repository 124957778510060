.emojiPickerIconWrap {display:inline-block; position:relative;}
.emojiPickerIcon {position:absolute; top:0; right:0; cursor:pointer; }

.emojiPickerIconWrap .white {background:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjc2Ljg5MXB4IiBoZWlnaHQ9IjI3Ni44OTFweCIgdmlld0JveD0iMCAwIDI3Ni44OTEgMjc2Ljg5MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjc2Ljg5MSAyNzYuODkxIg0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGN4PSIxMzguNDQ1IiBjeT0iMTM4LjQ0NSIgcj0iMTMyLjk0NSIvPg0KPGNpcmNsZSBmaWxsPSIjRkZGRkZGIiBjeD0iNjguMTIiIGN5PSIxMjUuMzk1IiByPSIxNi41MDciLz4NCjxjaXJjbGUgZmlsbD0iI0ZGRkZGRiIgY3g9IjIwOC42MTciIGN5PSIxMjUuMzk1IiByPSIxNi41MDgiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik02OC4xMiwxODIuMDM0DQoJYzAsMCw2OS43OTMsNzAuNzA0LDE0MC40OTgsMCIvPg0KPC9zdmc+DQo=') center center no-repeat; background-size:60%;}
.emojiPickerIconWrap .black {background:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjc2Ljg5MXB4IiBoZWlnaHQ9IjI3Ni44OTFweCIgdmlld0JveD0iMCAwIDI3Ni44OTEgMjc2Ljg5MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjc2Ljg5MSAyNzYuODkxIg0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGN4PSIxMzguNDQ1IiBjeT0iMTM4LjQ0NSIgcj0iMTMyLjk0NSIvPg0KPGNpcmNsZSBjeD0iNjguMTIiIGN5PSIxMjUuMzk1IiByPSIxNi41MDciLz4NCjxjaXJjbGUgY3g9IjIwOC42MTciIGN5PSIxMjUuMzk1IiByPSIxNi41MDgiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik02OC4xMiwxODIuMDM0DQoJYzAsMCw2OS43OTMsNzAuNzA0LDE0MC40OTgsMCIvPg0KPC9zdmc+DQo=') center center no-repeat; background-size:60%;}
.emojiPickerIconWrap .yellow {background:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjc2Ljg5MXB4IiBoZWlnaHQ9IjI3Ni44OTFweCIgdmlld0JveD0iMCAwIDI3Ni44OTEgMjc2Ljg5MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjc2Ljg5MSAyNzYuODkxIg0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRUJDMjAwIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGN4PSIxMzguNDQ1IiBjeT0iMTM4LjQ0NSIgcj0iMTMyLjk0NSIvPg0KPGNpcmNsZSBmaWxsPSIjRUJDMjAwIiBjeD0iNjguMTIiIGN5PSIxMjUuMzk1IiByPSIxNi41MDciLz4NCjxjaXJjbGUgZmlsbD0iI0VCQzIwMCIgY3g9IjIwOC42MTciIGN5PSIxMjUuMzk1IiByPSIxNi41MDgiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0VCQzIwMCIgc3Ryb2tlLXdpZHRoPSIxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik02OC4xMiwxODIuMDM0DQoJYzAsMCw2OS43OTMsNzAuNzA0LDE0MC40OTgsMCIvPg0KPC9zdmc+DQo=') center center no-repeat; background-size:60%;}
.emojiPickerIconWrap .grey, .emojiPickerIconWrap .gray {background:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjc2Ljg5MXB4IiBoZWlnaHQ9IjI3Ni44OTFweCIgdmlld0JveD0iMCAwIDI3Ni44OTEgMjc2Ljg5MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjc2Ljg5MSAyNzYuODkxIg0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRUJDMjAwIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGN4PSIxMzguNDQ1IiBjeT0iMTM4LjQ0NSIgcj0iMTMyLjk0NSIvPg0KPGNpcmNsZSBmaWxsPSIjRUJDMjAwIiBjeD0iNjguMTIiIGN5PSIxMjUuMzk1IiByPSIxNi41MDciLz4NCjxjaXJjbGUgZmlsbD0iI0VCQzIwMCIgY3g9IjIwOC42MTciIGN5PSIxMjUuMzk1IiByPSIxNi41MDgiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0VCQzIwMCIgc3Ryb2tlLXdpZHRoPSIxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik02OC4xMiwxODIuMDM0DQoJYzAsMCw2OS43OTMsNzAuNzA0LDE0MC40OTgsMCIvPg0KPC9zdmc+DQo=') center center no-repeat; background-size:60%;}

.emojiPicker {display:none; position:absolute; outline:none; border:none; box-shadow:0 0 7px #555; border-top-left-radius:4px; border-top-right-radius:4px;}
.emojiPicker div.emoji {width:1.3em; height:1.3em; position:relative; display:inline-block;}
.emojiPicker span.emoji {width:1.3em; height:1.3em; display:inline-block; position:relative; overflow:hidden; text-indent:-9999px; vertical-align:middle;}
.emojiPicker .hidden {display:none;}

.emojiPicker nav {position:relative; z-index:0; background-color:#f2f2f2; border-top-left-radius:4px; border-top-right-radius:4px;}
.emojiPicker nav div.tab {display:inline-block; margin:2% 1% 0 1%; padding:2% 2% 1% 2%; border-top-left-radius:4px; border-top-right-radius:4px; cursor:pointer;}
.emojiPicker nav div.tab.active {background-color:#fff; box-shadow:0 0 3px #ccc;}

.emojiPicker section {overflow:scroll; position:relative; z-index:10; background:#fff;}
.emojiPicker section div {width:40px; float:left; margin:3%;}
.emojiPicker section div:hover {cursor:pointer;}
